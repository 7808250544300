body {
  margin: 0;
  font-family: monospace;
}

.header {
  background-color: rgb(0, 0, 0, 0.95);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-description {
  font-size: 13px;
  padding: 10px;
  white-space: nowrap;
}

.font-row {
  display: flex;
  flex-direction: row;
}

.icon {
  padding: 10px;
  cursor: pointer;
}

.icon:hover {
  color: rgb(253, 102, 1);
}

.icon.reddit:hover, div.reddit {
  color: rgb(157, 240, 157);
}

.reddit canvas {
  border: 2px solid rgb(157, 240, 157);
}

.icon.github:hover, div.github {
  color:rgba(218, 159, 253, 0.95);
}

.github img {
  border: 2px solid rgba(218, 159, 253, 0.95);
}

.icon.soundcloud:hover, div.soundcloud {
  color:rgba(240, 162, 73, 0.95);
}

.soundcloud img {
  border:2px solid rgba(240, 162, 73, 0.95);
}

.icon.life:hover, div.life {
  color:rgba(129, 223, 226, 0.95);
}

.life img {
  border: 2px solid rgba(129, 223, 226, 0.95);
}

.box img, .box canvas {
  border-radius: 5px;
}

.hover-pane {
  height: 200px;
  padding-top: 20px;
  cursor: pointer;
}

.box {
  height: 200px;
  width: 200px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}
